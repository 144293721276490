/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //MOBLIE MENU CODE - START

        // Adding elements to menu that will help with styling
        var lastHeaderHeight = $('.header-top').height();
        $('#mobile-menu-hide').hide();
        $('#mobile-search-hide').hide();
        $('#mobile-search-bar-container').hide();
        $('#mobile-menu-hide').css('pointer-events','none');
        $('#mobile-menu-show').css('pointer-events','none');
        $('#mobile-search-hide').css('pointer-events','none');
        $('#mobile-search-show').css('pointer-events','none');
        $('.single-level-ul, .multi-level-ul').addClass('arrow-down');
        $('.single-level-ul, .multi-level-ul').children('a').after('<div class="mobile-click-area"></div>');

        if ($('.header-top').height() < 137) {
          $('#menu-primary_navigation').hide();
          $('.single-level-ul, .multi-level-ul').children('.sub-menu').css('display','none');
        } else {
          $('.mobile-click-area').hide();
        }

        // Show/hide menu with hamburger icon
        $('#mobile-menu-container').click( function(e) {
          toggleMobileMenu();
        });

        // Show/hide mobile search button
        $('#mobile-search-container').click( function(e) {
          toggleMobileSearch();
        });

        // add expand/collapse ability to sub menus
        $('.mobile-click-area').click(function (e) {
          $(this).next('.sub-menu').stop().slideToggle();
          if ($(this).parent().hasClass('arrow-up')) {
            $(this).parent().removeClass('arrow-up');
            $(this).parent().addClass('arrow-down');
          } else {
            $(this).parent().removeClass('arrow-down');
            $(this).parent().addClass('arrow-up');
          }
        });

        /* onResize event */

        // Get current width
        var windowWidth = $(window).width();

        $(window).resize(function() {

          if ($(this).width() != windowWidth) {
            windowWidth = $(this).width();
          } else {
            return false;
          }

          // MOBILE to DESKTOP
          $('#menu-primary_navigation').css('display','block');
          $('.single-level-ul, .multi-level-ul').removeClass('arrow-up');
          $('.single-level-ul, .multi-level-ul').removeClass('arrow-down');
          $('#mobile-search-hide').hide();
          $('#mobile-search-bar-container').hide();
          $('.mobile-click-area').hide();

          if (lastHeaderHeight !== 137 && $('.header-top').height() > 137) {

            // Bug fix for desktop menu items not showing after screen resize
            $('.single-level-ul, .multi-level-ul').children('.sub-menu').each(function() {
              if (this.style.removeProperty) {
                this.style.removeProperty('display');
              } else {
                this.style.removeAttribute('display');
              }
            });

            // DESKTOP to MOBILE
          } else {
            // hide navigation
            $('#menu-primary_navigation').css('display','none');
            $('#mobile-menu-hide').hide();
            $('#mobile-menu-show').show();
            $('#mobile-search-show').show();
            $('#mobile-menu-hide').css('pointer-events','none');
            $('#mobile-menu-show').css('pointer-events','none');
            $('.single-level-ul, .multi-level-ul').addClass('arrow-down');
            $('.mobile-click-area').show();

            $('.single-level-ul, .multi-level-ul').children('.sub-menu').slideUp();
            if (!$(this).hasClass('rotate-arrow')) {
              $(this).addClass('rotate-arrow');
            } else {
              $(this).removeClass('rotate-arrow');
            }
          }
          lastHeaderHeight = $('.header-top').height();
        });

        function toggleMobileMenu() {
          if ($('#mobile-search-hide').is(':visible')) {
            $('#mobile-search-hide').hide();
            $('#mobile-search-show').show();
            $('#mobile-search-bar-container').stop().slideToggle();
          }

          if ($('#mobile-menu-hide').is(':hidden')) {
            $('#mobile-menu-hide').show();
          } else if ($('#mobile-menu-hide').is(':visible')) {
            $('#mobile-menu-hide').hide();
          }

          if ($('#mobile-menu-show').is(':hidden')) {
            $('#mobile-menu-show').show();
          } else if ($('#mobile-menu-show').is(':visible')) {
            $('#mobile-menu-show').hide();
          }
          $('#menu-primary_navigation').stop().slideToggle();
        }

        function toggleMobileSearch() {
          if ($('#mobile-menu-hide').is(':visible')) {
            $('#mobile-menu-hide').hide();
            $('#mobile-menu-show').show();
            $('#menu-primary_navigation').stop().slideToggle();
          }

          if ($('#mobile-search-hide').is(':hidden')) {
            $('#mobile-search-hide').show();
          } else if ($('#mobile-search-hide').is(':visible')) {
            $('#mobile-search-hide').hide();
          }

          if ($('#mobile-search-show').is(':hidden')) {
            $('#mobile-search-show').show();
          } else if ($('#mobile-search-show').is(':visible')) {
            $('#mobile-search-show').hide();
          }
          $('#mobile-search-bar-container').stop().slideToggle();
          $('.mobile-search-bar > input').focus();
        }
        //MOBLIE MENU CODE - END

        // article overview code - START
        $('.article-grid .es-data').find('.article-tile').each(function() {
          $(this).addClass('vc_col-md-4');
        });
        // article overview code - END

        //FAQ SLIDER

        $('.question-block > .question').click( function(e) {
          $(this).next().stop().slideToggle();
          $(this).find('.button').toggleClass('open');
        });

        $(document).click(function(e) {
          if ($('.category-select-container').hasClass('dropdown-open')) {
            $('.category-select-item:nth-of-type(n+2)').slideUp();
            $('.category-select-container').removeClass('dropdown-open');
          }
        });

        //category dropdown select - START

        $('.category-select-container').click(function(e) {
          e.stopPropagation();
        });

        $('.category-select-item').each(function() {
          var $container = $(this).closest('.category-select-container');

          $(this).click(function(ev) {
            /*
             * We don't want the link to go to new
             * page right away as we want the dropdown
             * to close first. Also we need to stopPropagation
             * as a click on the body triggers the dropdown to close
             * which conflicts with this event logic.
            */
            ev.stopPropagation();
            ev.preventDefault();

            // Get the link value
            var linkTarget = $(this).find('a').attr('href');

            // Sort here
            var ul = $(this).closest('ul');
            var items = $(ul[0]).children('li');

            // Get the clicked item and place it first in the list
            var firstItem = ul.find('.category-select-item:first-of-type');
            $(this).insertBefore(firstItem);

            var sortTable = [];
            $.each(items, function(i, item) {
              if (i > 0) {
                var k = $(item).text();
                var v = $(item).html();
                var entry = {
                  key: k,
                  val: v
                };
                sortTable.push(entry);
              }
            });
            var sortedList = sortTable.sort(function(a,b) {
              var sort;
              var textA = a.key.toLowerCase();
              var textB = b.key.toLowerCase();
              if (textA < textB) {
                sort = -1;
              } else if (textA > textB) {
                sort = 1;
              } else {
                sort = 0;
              }
              return sort;
            });
            $.each(items, function(i, item) {
              if (i > 0) {
                $(item).html(sortedList[i - 1].val);
              }
            });

            if ($container.hasClass('dropdown-open')) {
              $container.find('.category-select-item:nth-of-type(n+2)').slideUp('slow', function() {
                // After the slide up is complete, go to the link's target
                window.location.href = linkTarget;
              });
              $container.removeClass('dropdown-open');
            }
            else {
              $container.find('.category-select-item:nth-of-type(n+2)').slideDown();
              $container.addClass('dropdown-open');
            }
          });
          $container.find('.category-select-item:first-of-type a').click(function(ev) {
            ev.preventDefault();
          });
        });

        $('.articles-resources .category-select-item a').each(function() {
          $(this).click(function(e) {
            e.stopPropagation();
            firstItem = $('.category-select-item:first-of-type');
            $(this).parent().insertBefore(firstItem);
            firstItem.insertAfter($(this).parent());
            if ($('.category-select-container').hasClass('dropdown-open')) {
              $('.category-select-item:nth-of-type(n+2)').slideUp();
              $('.category-select-container').removeClass('dropdown-open');
            }
            else {
              $('.category-select-item:nth-of-type(n+2)').slideDown();
              $('.category-select-container').addClass('dropdown-open');
            }
          });
        });

        $('.agree-checkbox').find('input[type="checkbox"]').attr('id', "radio-413");

        $('.radio-label, .radio-label + span').click(function() {
          $('.radio-label').css('background-color', '#1F93C3');
          $('.radio-label + span span').hide();
        });

        window.validateForm = function() {
          $('.wpcf7-form').find('input[type="text"], input[type="email"], textarea').each(function() {
            if ($(this).hasClass('wpcf7-not-valid'))
            {
              $(this).val('');
              $(this).attr('placeholder', 'This Field is Required');
            }
          });
          if ($('.agree-checkbox').hasClass('wpcf7-not-valid')) {
            $('.radio-label + span').html('I agree<span style="color: red; margin-left: 12px;">You must agree to terms to submit the form</span>');
          }
        };
        //MOBLIE MENU CODE - END

        // article overview code - START
        $('.article-grid .es-data').find('.article-tile').each(function() {
          $(this).addClass('vc_col-md-4');
        });
        // article overview code - END

        //FAQ SLIDER

        // $('.question-block > .question').click( function() {
        //   $(this).next().stop().slideToggle();
        //   $(this).find('.button').toggleClass('open');
        // });

        //category dropdown select - START

        $('.category-select-container').click(function(e) {
          console.log(e);
          $(this).find('.category-select-item:nth-of-type(n+2)').slideToggle();
          $(this).toggleClass('dropdown-open');
        });

        // $('.category-select-item').each(function() {
        //     $(this).click(function(e) {
        // 	firstItem = $('.category-select-item:first-of-type');
        // 	$(this).insertBefore(firstItem);
        // 	firstItem.insertAfter($(this));
        //
        // 	// $('.category-select-container').toggleClass('dropdown-open');
        // 	if ($('.category-select-container').hasClass('dropdown-open')) {
        // 	    $('.category-select-item:nth-of-type(n+2)').slideUp();
        // 	    $('.category-select-container').removeClass('dropdown-open');
        // 	}
        // 	else {
        // 	    $('.category-select-item:nth-of-type(n+2)').slideDown();
        // 	    $('.category-select-container').addClass('dropdown-open');
        // 	}
        // 	e.stopPropagation();
        //     });
        // });

        // window.validateForm = function() {
        //   $('.wpcf7-form').find('input[type="text"], input[type="email"], textarea').each(function() {
        //     if ($(this).hasClass('wpcf7-not-valid'))
        //     {
        //       $(this).val('');
        //       $(this).attr('placeholder', 'This Field is Required');
        //     }
        //   });
        // };

        // Footer text cutoff
        $('.footer').find('a').each(function() {
          if ($(this).text().length >= 30) {
            $(this).text($(this).text().substring(0, 30));
            $(this).append('...');
          }
        });

        $('.article-sidebar-item').find('a').each(function() {
          if ($(this).text().length >= 30) {
            $(this).text($(this).text().substring(0, 30));
            $(this).append('...<span class="navigation-arrow">&nbsp;</span>');
          }
        });

        // Print functionality on lawyer pages
        $('.print-button').click(function() {
          window.print();
        });

        var recentArticlesFunc = function(self, return_data){
          var new_articles = '';

          var data = return_data.json;

          var pagedata = {
            'count' : data.count,
            'page'  : data.page,
            'cat'   : data.cat
          };

          for(var i = 0; i < data.posts.length; i++){
            new_articles += data.posts[i];
          }

          $(self).parent().find('.article-tile').remove();

          MAS.Ajax.setArticleData($(self).parent().find('.dataobj'), pagedata);

          $(self).parent().find('.container').append($(new_articles));

        };

        $('.prev-nav').on('click', function(){
          //get the data from the dom
          var self = this;

          var data = MAS.Ajax.parseArticleData($(this).parent().find('.dataobj'), {'count' : -1, 'page' : -1, 'cat' : -1, 'post_status' : 'publish'});

          data.page = data.page - 1;

          MAS.Ajax.ajaxCall('wp-admin', 'articles_ajax', data, function(d){ recentArticlesFunc(self, d); });
        });

        $('.next-nav').on('click', function(){
          //get the data from the dom
          var self = this;

          var data = MAS.Ajax.parseArticleData($(this).parent().find('.dataobj'), {'count' : -1, 'page' : -1, 'cat' : -1, 'post_status' : 'publish'});

          data.page = data.page + 1;

          MAS.Ajax.ajaxCall('wp-admin', 'articles_ajax', data, function(d){ recentArticlesFunc(self, d); });
        });

        $('.shareBtn .holder').on('click',function(){
          $('.shareBtn').toggleClass('active').find('.inner').fadeToggle();
        });

        $('.shareBtn .inner a').on('click',function(){
          $('.shareBtn').removeClass('active').find('.inner').fadeOut();
        });

        $('.shareBtn .close-share').on('click',function(){
          $('.shareBtn').removeClass('active').find('.inner').fadeOut();
        });

        $('.lawyer-banner-control').on('click', function(){
          $('.lawyer-banner.show').removeClass('show');
          $('.lawyer-' + $(this).attr('data-lb-id')).addClass('show');
        });

        // common end
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    'faqs': {
      init: function() {
        //MAS.faq = new FAQ();

        //MAS.faq.init();
      }
    },

    'articles_resources' : {
      // init : function() {
      //   var articles_load_more_func = function(t, c, d){
      //
      //     var target = '.' + t;
      //
      //     var count = c;
      //
      //     var data = d.json;
      //
      //     var new_articles = '';
      //
      //     var pagedata = {
      //       'count'  : count,
      //       'page'   : data.page,
      //       'cat'    : data.cat,
      //       'target' : t,
      //     };
      //
      //     for(var i = 0; i < data.posts.length; i++){
      //       new_articles += data.posts[i];
      //     }
      //
      //     $(target).find('.article-tile').slice(0, data.posts.length).remove();
      //
      //     setArticleData($(target).find('.es-data'), pagedata);
      //
      //     $(target).append($(new_articles));
      //   };
      //
      //   $('.load-more').on('click', function(){
      //     var self = this;
      //
      //     var more_count = parseInt($(this).attr('data-amt'));
      //
      //     var target = $(this).attr('data-target');
      //
      //     target = 'article-grid';
      //
      //     var func = '';
      //
      //     var data = parseArticleData(
      //       $('.' + target).find('.es-data'),
      //       {'count' : -1, 'page' : -1, 'cat' : -1}
      //     );
      //
      //     if(data.count != more_count){
      //       data.page = (data.count * (data.page + 1)) / more_count;
      //       data.count = more_count;
      //     }
      //
      //     data.page++;
      //
      //     MAS.Ajax.ajaxCall('wp-admin', 'articles_ajax', data, function(d){ articles_load_more_func(target, data.count, d); });
      //
      //     return false;
      //   });
      //
      //   var articles_load_all_func = function(t, c, d){
      //
      //     var target = '.' + t;
      //
      //     var count = c;
      //
      //     var data = d.json;
      //
      //     var new_articles = '';
      //
      //     var pagedata = {
      //       'count'  : count,
      //       'page'   : data.page,
      //       'cat'    : data.cat,
      //       'target' : t,
      //     };
      //
      //     for(var i = 0; i < data.posts.length; i++){
      //       new_articles += data.posts[i];
      //     }
      //
      //     $(target).find('.article-tile').remove();
      //
      //     setArticleData($(target).find('.es-data'), pagedata);
      //
      //     $(target).append($(new_articles));
      //   };
      //
      //   $('.load-all').on('click', function(){
      //     var self = this;
      //
      //     var target = $(this).attr('data-target');
      //
      //     target = 'article-grid';
      //
      //     var func = '';
      //
      //     var data = parseArticleData(
      //       $('.' + target).find('.es-data'),
      //       {'count' : -1, 'page' : -1, 'cat' : -1}
      //     );
      //
      //     data.page++;
      //
      //     MAS.Ajax.ajaxCall('wp-admin', 'articles_ajax', data, function(d){ articles_load_more_func(target, data.count, d); });
      //
      //     return false;
      //   });
      //
      //   var populate_article_grid = function(d){
      //     var new_articles = '';
      //
      //     var data = d.json;
      //
      //     var pagedata = {
      //       'count' : data.count,
      //       'page'  : data.page,
      //       'cat'   : data.cat
      //     };
      //
      //     for(var i = 0; i < data.posts.length; i++){
      //       new_articles += data.posts[i];
      //     }
      //
      //
      //     $('.article-tile').remove();
      //
      //     setArticleData($('.es-data'), pagedata);
      //
      //     $('.article-grid').append($(new_articles));
      //   };
      //
      //   $('.category-select a').on('click', function(){
      //     data  = parseArticleData($('.article-grid .es-data'), {'count' : -1});
      //
      //     data.page = 0;
      //
      //     data.cat = parseInt($(this).attr('data-id'));
      //
      //     MAS.Ajax.ajaxCall('wp-admin', 'articles_ajax', data, function(d){populate_article_grid(d); });
      //     return false;
      //   });
      // },
    },

    'our_lawyers' : {


      //   init : function() {
      // var populate_lawyer_grid = function(d){
      //
      //     var data = d.json;
      //
      //     var new_articles = '';
      //
      //     for(var i = 0; i < data.posts.length; i++){
      // 	new_articles += data.posts[i];
      //     }
      //
      //     $('.lawyer-grid').find('.lawyer-section').remove();
      //
      //     $('.lawyer-grid').append($(new_articles));
      // };
      //
      // $('.category-select a').on('click', function(){
      //           data = {};
      //           data.cat = parseInt($(this).attr('data-id'));
      //
      //           MAS.Ajax.ajaxCall('wp-admin', 'lawyer_filter', data, function(d){populate_lawyer_grid(d); });
      //     return false;
      // });
      //   }
    },

    'practice_areas' : {
      init : function() {
        // var practice_areas_load_more_func = function(t, c, d){
        //
        //   var target = '.' + t;
        //
        //   var count = c;
        //
        //   var data = d.json;
        //
        //   var new_articles = '';
        //
        //   var pagedata = {
        //     'count'  : count,
        //     'page'   : data.page,
        //     'cat'    : data.cat,
        //     'target' : t,
        //   };
        //
        //   for(var i = 0; i < data.posts.length; i++){
        //     new_articles += data.posts[i];
        //   }
        //
        //   $(target).find('.practice-section').slice(0, data.posts.length).remove();
        //
        //   setArticleData($(target).find('.es-data'), pagedata);
        //
        //   $(target).append($(new_articles));
        // };
        //
        // $('.load-more').on('click', function(){
        //   var self = this;
        //
        //   var more_count = parseInt($(this).attr('data-amt'));
        //
        //   var target = $(this).attr('data-target');
        //
        //   target = 'practice-grid';
        //
        //   var func = '';
        //
        //   var data = parseArticleData(
        //     $('.' + target).find('.es-data'),
        //     {'count' : -1, 'page' : -1, 'cat' : -1}
        //   );
        //
        //   if(data.count != more_count){
        //     data.page = (data.count * (data.page + 1)) / more_count;
        //     data.count = more_count;
        //   }
        //
        //   data.page++;
        //
        //   MAS.Ajax.ajaxCall('wp-admin', 'practice_area_filter', data, function(d){ practice_areas_load_more_func(target, data.count, d); });
        //
        //   return false;
        // });
        //
        // var practice_areas_load_all_func = function(t, c, d){
        //
        //   var target = '.' + t;
        //
        //   var count = c;
        //
        //   var data = d.json;
        //
        //   var new_articles = '';
        //
        //   var pagedata = {
        //     'count'  : count,
        //     'page'   : data.page,
        //     'cat'    : data.cat,
        //     'target' : t,
        //   };
        //
        //   for(var i = 0; i < data.posts.length; i++){
        //     new_articles += data.posts[i];
        //   }
        //
        //   $(target).find('.practice-section').remove();
        //
        //   setArticleData($(target).find('.es-data'), pagedata);
        //
        //   $(target).append($(new_articles));
        // };
        //
        // $('.load-all').on('click', function(){
        //   var self = this;
        //
        //   var more_count = parseInt($(this).attr('data-amt'));
        //
        //   var target = $(this).attr('data-target');
        //
        //   target = 'practice-grid';
        //
        //   var func = '';
        //
        //   var data = parseArticleData(
        //     $('.' + target).find('.es-data'),
        //     {'count' : -1, 'page' : -1, 'cat' : -1}
        //   );
        //
        //   data.page++;
        //
        //   MAS.Ajax.ajaxCall('wp-admin', 'practice_area_filter', data, function(d){ practice_areas_load_all_func(target, data.count, d); });
        //
        //   return false;
        // });

        // var populate_practice_grid = function(d){
        //
        //   var data = d.json;
        //
        //   var new_practices = '';
        //
        //
        //   var pagedata = {
        //     'count'  : data.count,
        //     'page'   : data.page,
        //     'cat'    : data.cat,
        //   };
        //
        //   for(var i = 0; i < data.posts.length; i++){
        //     new_practices += data.posts[i];
        //   }
        //
        //   setArticleData($('.practice-grid').find('.es-data'), pagedata);
        //
        //   $('.practice-grid').find('.practice-section').remove();
        //
        //   $('.practice-grid').append($(new_practices));
        // };

        // $('.category-select a').on('click', function(){
        //     data = {};
        //     data.cat = parseInt($(this).attr('data-id'));
        //     data.page = 0;
        //
        //     MAS.Ajax.ajaxCall('wp-admin', 'practice_area_filter', data, function(d){ populate_practice_grid(d); });
        //     return false;
        // });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
